import axios, {  Method, AxiosRequestConfig } from "axios";
import AuthService from "../auth/authService";
export interface ICallApi {
  url: string;
  method: Method;
  headers?: Record<string, unknown>;
  data?: unknown;
  params?: Record<string, unknown>;
  timeout?: 30000;
  isPublic?: boolean;
  [x: string]: any;
}
class ApiService {
  async CallApi({
    url,
    method,
    headers,
    data,
    params,
    timeout,
    isPublic = false,
    ...rest
  }) {
    const config: AxiosRequestConfig = {
      method,
      data,
      params,
      url,
      timeout,
      headers: {
        Accept: "application/json",
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        ...headers,
      },
      ...rest,
    };
    if (isPublic) {
      return axios.request(config);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const isAuthenticated = await AuthService.isAuthenticated();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const auth = await AuthService.loadAuthService();
      const accessToken = await AuthService.getProfileToken()
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return await axios.request(config);
    } catch (error) {
      console.log("error in api response:",error);
      return error;
    }
  }
}

export default new ApiService();
