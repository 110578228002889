import React, { useState } from "react";
import { IconTime24 } from "@acca-digital/react-icons";
import Accordion from "../../Accordion/Accordion";
import Doughnutcircle from "../../Doughnut/Doughnut";
import { useLocation } from "react-router-dom";

const TimeManagemenTmData = (props) => {
  const [show, setShow] = useState(false);
  /* loacation path to check */
  const location = useLocation();
  const dataToDisplayTm = props.TmData.map((TmData) => {
    const {
      code,
      displayName,
      mark,
      timeFeedback,
      timeNextStepFeedback,
      timeSpent,
    } = TmData;
    //console.log(TmData.feedbacks);

    return (
      <div className="accordiantab-wrapper " key={code}>
        <div className="accordian-tab ">
          <div className="container-pef ">
            <div className="row">
              <div
                className="col-1"
                style={{ display: show ? "display" : "none" }}
              >
                {/* <Avatar
                  style={{ background: "#64c8fa" }}
                  initials={code}
                /> */}
              </div>
              <div className="col-2">
                <h4>{displayName}</h4>
                <p className="u-overline-caption h800 padding-tm">
                  {" "}
                  <span className="clockIcon">
                    <IconTime24 />
                  </span>
                  your time : <span className="u-font-bold">{timeSpent}</span>
                </p>
              </div>
              <div className="column-reverseMobile">
                <div
                  className="col-3"
                  style={{ padding: show ? "padding" : "0px" }}
                >
                  {/* {TmData.feedbacks.map((feedbacks) => {
                  console.log(feedbacks);
                  return (
                    <Accordion
                      accordionList={feedbacks.feedbackType}
                      accordionPara={feedbacks.feedback}
                    />
                  );
                })} */}
                  <Accordion
                    accordionList={"Time management feedback"}
                    accordionPara={timeFeedback}
                  />
                  <Accordion
                    accordionList={"Next steps"}
                    accordionPara={timeNextStepFeedback}
                  />
                </div>
                <div className="col-4 timemgm-space">
                  <h3 className="u-text-center h600 u-padding-bottom-1">
                    {location.pathname === "/time-management" ? "Result" : ""}
                  </h3>
                  <Doughnutcircle marks={mark} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <>{dataToDisplayTm}</>;
};

export default TimeManagemenTmData;
