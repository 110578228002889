import React from "react";
import { IconArrowRight24 } from "@acca-digital/react-icons";
import { Anchor } from "acca-design-system";
import constructedIcon from "../../assets/icons/constructedIcon.svg";

const Constructed = (props) => {
  // const listIcon = () => {
  //   return <IconArrowRight24 />;
  // };

  const cData = props.content.values;
  const ConstructedResult = cData.map((cData) => {
    // console.log(cData);
    const { code, displayName, mark } = cData;

    return (
      <div className="feedback-column" key={code}>
        <ul>
          <li>{displayName}</li>
          <li>{mark}%</li>
        </ul>
      </div>
    );
  });

  return (
    <>
      <div className="grid-item">
        <div className="icons-list">
          <img src={constructedIcon} alt="" />
        </div>
        <h2>{props.content.displayName}</h2>
        <p>{props.content.displayDesc}</p>
        <p className="marks u-text-right">Marks</p>
        {ConstructedResult}

        <Anchor
          className="u-padding-top-2"
          onClick={function noRefCheck() {}}
          to="/constructed-response"
        >
          View detailed results <IconArrowRight24 />
        </Anchor>
      </div>
    </>
  );
};

export default Constructed;
