import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { Footer, LoadingDots } from "acca-design-system";
import PefSideBar from "./sidebar/PefSidebar";
import DashBoardHeader from "./DashBoardHeader";
import Appliedskill from "../Appliedskill/Appliedskill";
import DashBoardNav from "./DashBoardNav";
import NextSteps from "./NextSteps";
import AuthService from "../../auth/authService";
import ApiService from "../../apis/apiService";
import apiConfigs from "../../configs/apiConfigs";
import { singlePef } from "../../actions";
import Passrate from "./Passrate";
import DidyouKnow from "./DidyouKnow";
// import useContent from "../../hooks/useContent";
// import LearningSupport from "./LearningSupport";

const DashBoard = (props) => {
  // console.log("props", props);

  const [scroll, setScroll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [siglePefData, setSinglePefData] = useState({});
  const [HamburgerClick, setHamburgerClick] = useState(false);
  const ref = useRef(null);

  const dispatch = useDispatch();

  const nextStepCard = props.dashboardContent.nextStepsCard;
  const bannerHeader = props.dashboardContent.resultCard;

  const passRate = props.dashboardContent.studySupportHeading;
  const passRatecard =
    props.dashboardContent.learningSupportCards[0].learningSupportCard1;
  useEffect(() => {
    setLoading(true);
    const fetchSinglePefData = async () => {
      const auth = await AuthService.loadAuthService();
      const accaId = auth.idTokenClaims.userid;
      const pefId = props.currentPefId;

      const response = await ApiService.CallApi({
        url: apiConfigs.endpoints.user.singlePefDetails(accaId, pefId),
        method: "GET",
        timeout: 50000,
      })
        .then((resp) => {
          setSinglePefData(resp.data);
          dispatch(singlePef(resp.data));
          setLoading(false);
        })
        .catch(function (res) {
          if (res instanceof Error) {
          } else {
          }
        });
    };
    fetchSinglePefData();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  window.addEventListener("load", () => {
    var myBtn = document.getElementsByClassName("nav-bar__menu-btn");
    var alertNow = function () {
      setHamburgerClick(true);
    };
    for (var i = 0; i < myBtn.length; i++) {
      myBtn[i].addEventListener("click", alertNow, false);
    }
  });

  useEffect(
    (HamburgerClick, scroll) => {
      // console.log("className 👉️", ref.current.className);

      if (ref.current.classList.contains("bg-white")) {
        // console.log("Element contains class");
      } else {
        // console.log("Element does NOT contain class");
      }
    },
    [HamburgerClick, scroll]
  );
  const bg = scroll || HamburgerClick ? "bg-white" : "bg-transparent";

  return (
    <>
      <div className="column-layoutsplit">
        <div ref={ref} className={`navbar-wrapper ${bg}`}>
          <PefSideBar />
        </div>
        {loading ? (
          <LoadingDots />
        ) : (
          <>
            <div className="dashboard-header-wrapper">
              <div className="bg-gradient"> </div>
              <div className="image-wrapper">
                <div
                  className="image-wrapper img"
                  style={{
                    backgroundImage: `url(${bannerHeader.images[2].url})`,
                  }}
                  alt=""
                />
              </div>
              <div className="content-wrapper">
                <DashBoardNav />
                <DashBoardHeader headerTitle={bannerHeader.heading} />
              </div>
            </div>

            <Appliedskill />
            <NextSteps content={nextStepCard} />
            <Passrate title={passRate} keyValue={passRatecard} />
            <DidyouKnow />

            <Footer className="container-pef " children={""}></Footer>
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  // console.log("State in dashboard:", state);
  return {
    singlePef: state.singlePef,
    dashboardContent: state.dashboardData.dashboard,
    currentPefId: state.currentPefId.pef,
  };
};
export default connect(mapStateToProps)(DashBoard);
// export default DashBoard;
