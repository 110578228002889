import LandingPage from "../components/Landingpage/LandingPage";
import DashBoard from "../components/Dashboard/Dashboard";
import TimeMangementDetails from "../components/Dashboard/timeManagement/TimeMangementDetails";
import syllabusDetails from "../components/Dashboard/syllabus/SyllabusDetails";
import ObjectiveDetails from "../components/Dashboard/objectiveTestSkill/ObjectivesDetails";
import ConstructedResponseDetails from "../components/Dashboard/constructedResponse/ConstructedResponseDetail";
import ErrorPage from "../components/Errorpage/Errorpage";
const routes = [
  {
    path: "/",
    component: LandingPage,
    exact: true,
  },
  {
    path: "/dashboard",
    component: DashBoard,
    exact: true,
  },
  {
    path: "/time-management",
    component: TimeMangementDetails,
    exact: true,
  },
  {
    path: "/syllabus",
    component: syllabusDetails,
    exact: true,
  },
  {
    path: "/objective-test-skills",
    component: ObjectiveDetails,
    exact: true,
  },
  {
    path: "/constructed-response",
    component: ConstructedResponseDetails,
    exact: true,
  },
  {
    path: "/error",
    component: ErrorPage,
    exact: false,
  },
];
export default routes;
