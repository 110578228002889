import React, { useState } from "react";
import { connect } from "react-redux";
import {
  LinkList,
  LinkListItem,
  Modal,
  BodyCopy,
  PageHeader,
} from "acca-design-system";
import { IconQuestionSolid16, IconHelp64 } from "@acca-digital/react-icons";
import { useLocation } from "react-router-dom";

import ExamModalContent from "./ExamModalContent";

const DashBoardNav = (props) => {
  const varTextColor = props.navBg;

  const [showModal, setShowModal] = useState(null);

  /* loacation path to check */
  const location = useLocation();
  const urlPath = location.pathname;
  // const download = () => {
  //   return <IconDownload16 />;
  // };

  const examIcon = () => {
    if (urlPath === "/dashboard") {
      return <IconQuestionSolid16 />;
    } else {
      return <IconHelp64 />;
    }
  };

  return (
    <div className={`dashBoardNavBar ${varTextColor}`}>
      <div className="container-pef ">
        <div className="split-column">
          <div className="link-icon">
            <LinkList>
              <LinkListItem
                text="Exam marks explained"
                icon={examIcon}
                onClick={() => setShowModal(true)}
              />
            </LinkList>
          </div>
          {showModal && (
            <Modal
              className="custom-modal"
              closeModal={() => setShowModal(false)}
              closeLinkText="Close"
              autoHeight
              fullScreen={true}
              lotsOfContent={true}
            >
              <BodyCopy>
                <PageHeader quote="Exam marks explained" />
                <p className="paratitle">
                  We use exam mark bands to show how you performed in each exam
                  area
                </p>
                <ExamModalContent modalData={props.singlePef.singlePef} />
              </BodyCopy>
            </Modal>
          )}
          {/* temporarily disabled button for phase 1 */}
          {/* <div className="downloadButton">
            <Button
              icon={download}
              onClick={download}
              text="Download"
              iconPosition="right"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { singlePef: state.singlePef };
};

export default connect(mapStateToProps)(DashBoardNav);
