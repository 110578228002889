import { combineReducers } from "redux";
import { pefStatus } from "./pefStatus";
import { fetchPefData } from "./pefData";
import { singlePef } from "./singlePef";
import { landingContent } from "./landingpageContent";
import { textColorChange } from "./TextColor";
import { dashboardAemData } from "./dashboardData";

import { ACTION_TYPES } from "../constants/Constants";

const pefId = "";

export const currentPefId = (state = pefId, action) => {
  switch (action.type) {
    case ACTION_TYPES.PEFID:
      return { ...state, pef: action.payload };
    default:
      return state;
  }
};
export default combineReducers({
  pef: pefStatus,
  pefData: fetchPefData,
  singlePef: singlePef,
  currentPefId: currentPefId,
  // landingContent: landingContent,
  dashboardData: dashboardAemData,
  textColorChange: textColorChange,
});
