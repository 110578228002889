import React from "react";

import { PageHeader } from "acca-design-system";
import parse from "html-react-parser";
const LandingPageHeader = (props) => {
  const pageTitle = props.titleHd;
  const pageSubtile = props.subtitle;

  return (
    <div className="landing-pageheader container-pef ">
      <div className="pageTitle">
        <PageHeader pageTitle="" quote={pageTitle} />
        <p className="subTitle h-600">
          {parse(`${pageSubtile}`)}

        </p>
      </div>
    </div>
  );
};

export default LandingPageHeader;
