import { ACTION_TYPES } from "../constants/Constants";
const initialState = {};
export const singlePef = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case ACTION_TYPES.FETCH_SINGLE_PEF_DATA:
      return { ...state, singlePef: action.payload };
    default:
      return state;
  }
};
