import React from "react";
import "../../styles/App.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import AuthProvider from "../../containers/AuthProvider";
import WithErrorHandling from "./AppInitialiser";
import { persistor, store } from "../../store/Store";

function App() {
  return (
    <div className="main-Wrapper">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <WithErrorHandling />
          </AuthProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
