import { connect } from "react-redux";
import parse from "html-react-parser";
import React from "react";

const Helpsupport = (props) => {

  const helpCardValue = props.helpCardData;
  const helpTitle = props.helpCardData.helpAndSupportSectionHeading.text;

  const helpCardsHdBox1 = helpCardValue.helpCards[0].helpCard1.heading;
  const card1Link = helpCardValue.helpCards[0].helpCard1.link;
  const helpCardsParBox1 = helpCardValue.helpCards[0].helpCard1.text;
  const helpCardImg1 = helpCardValue.helpCards[0].helpCard1.images[2].url;

  const helpCardsHdBox2 = helpCardValue.helpCards[1].helpCard2.heading;
  const card2Link = helpCardValue.helpCards[1].helpCard2.link;
  const helpCardsParBox2 = helpCardValue.helpCards[1].helpCard2.text;

  const helpCardsHdBox3 = helpCardValue.helpCards[2].helpCard3.heading;
  const card3Link = helpCardValue.helpCards[2].helpCard3.link;
  const helpCardsParBox3 = helpCardValue.helpCards[2].helpCard3.text;

  const htmlString = helpCardsParBox1;
  // const imageClick = (link) => {
  //   window.open(link, "_blank");
  // };
  return (
    <>
      <div className="help-wrapper container-pef ">
        <h3 className="h600">{helpTitle}</h3>
        <div className="help-container">
          <div className="help-container-column1">
            <a href={card1Link} target="_blank" rel="noreferrer">
              <div className="desktop-image" style={{ backgroundImage: `url(${helpCardImg1})` }} />
              <img className="mobile-image" src={helpCardImg1} alt="" />
              <div className="img-content">
                <p className="overline-heading u-white u-text-left u-padding-left-0 u-padding-left-0 u-padding-bottom-0.5">
                  {" "}
                  {helpCardsHdBox1}
                </p>


                <h1
                  className="u-white"
                  dangerouslySetInnerHTML={{ __html: htmlString }}
                />
              </div>
            </a>
          </div>

          <div className="help-container-column2">
            <a href={card2Link} target="_blank" rel="noreferrer">
              <div className="column-report">
                <p>{helpCardsHdBox2}</p>
                <h2 className="h600"> {parse(`${helpCardsParBox2}`)}</h2>
              </div>
            </a>

            <a href={card3Link} target="_blank" rel="noreferrer">
              <div className="column-report">
                <p>{helpCardsHdBox3}</p>
                <h2 className="h600"> {parse(`${helpCardsParBox3}`)}</h2>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const mapHelpSupport = (state) => {
  return {
    // landingContent: state.landingContent,
  };
};
export default connect(mapHelpSupport)(Helpsupport);
