// import { IconProfile16 } from "@acca-digital/react-icons";

export const navigation = [
  {
    id: "dashboard",
    name: "Performance dashboard",
    to: "/dashboard",
    active: true,
  },

  {
    id: "timemanagement",
    name: "Time management",
    // icon: 'IconPayment16',
    to: "/time-management",
  },
  {
    id: "syllabus",
    name: "Syllabus",
    to: "/syllabus",
  },
  {
    id: "objective",
    name: "Objective test skills",
    to: "/objective-test-skills",
  },
  {
    id: "constructedResponse",
    name: "Constructed response",
    to: "/constructed-response",
  },
];

export const controls = [];
