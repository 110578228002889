/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from "react";

import AuthService from "../auth/authService";
import { LoadingDots } from "acca-design-system";

const AUTH_ERROR_CODES = {
  forgotPassword: "AADB2C90118",
};

interface IdTokenClaims {
  idTokenClaims: {
    extension_accaid?: string;
    iat: string;
    exp: string;
    auth_time: string;
  };
}

const AuthProvider: FC = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    async function fetchAuthService() {
      try {
        const auth = await AuthService.loadAuthService();
        const isAuthenticated = AuthService.isAuthenticated();

        const isExpired =
          (auth?.idTokenClaims?.exp || "0") <
          Math.round(Date.now() / 1000).toString();

        if (isAuthenticated && isExpired) {
          AuthService.login();
          return;
        }

        setIsReady(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const { errorMessage } = error;
        if (
          errorMessage &&
          errorMessage.includes(AUTH_ERROR_CODES.forgotPassword)
        ) {
          AuthService.forgotPassword();
        }
      }
    }
    fetchAuthService();
  }, []);

  return <>{isReady ? children : <LoadingDots />}</>;
};

export default AuthProvider;
