import { ACTION_TYPES } from "../constants/Constants";

const pef = {};

export const pefStatus = (state = pef, action) => {
  // console.log("Action:", action);
  switch (action.type) {
    case ACTION_TYPES.PEF_AVAILAIBLE:
      return { ...state, pef: action.payload };
    default:
      return state;
  }
};
