/* eslint-disable no-script-url */
import React from "react";
import { useDispatch } from "react-redux";

import { Avatar } from "acca-design-system";
import {
  IconCrossSolid16,
  IconTickSolid16,
  IconArrowRight24,
} from "@acca-digital/react-icons";
import { useHistory } from "react-router-dom";
import routes from "../../constants/Routes";

import { currentPefId } from "../../actions";
const Exam = (props) => {
  const dispatch = useDispatch();
  const dashboard = routes.filter((dashboard) =>
    dashboard.path.includes("dashboard")
  );

  const history = useHistory();
  function handleClick(e, pefId) {
    e.preventDefault();
    dispatch(currentPefId(pefId));
    history.push({
      pathname: "/dashboard",
      pefId: pefId,
    });
    // history.push(routes);
  }

  const examData = props.pefData.map((pefData) => {
    return (
      <>
        <a
          className="exam-container"
          key={pefData.pefId}
          onClick={(e) => {
            handleClick(e, pefData.pefId);
          }}
        >
          <div className="col-1">
            <Avatar colour="grey" initials={pefData.examSubjectCode} />
          </div>
          <div className="col-2 u-text-left">
            {pefData.examSubjectNameDisplay}
          </div>
          <div className="col-3">
            {pefData.examResultDisplayCode === "Pass" ? (
              <IconTickSolid16
                className="iconLeft"
                style={{ color: "#8CC84B" }}
              />
            ) : (
              <IconCrossSolid16
                className="iconLeft"
                style={{ color: "#F24141" }}
              />
            )}
            <div className="result-data">
              {pefData.examResultDisplayCode}{" "}
              {pefData.examResultFinalMarkPercentage} %
            </div>
          </div>
          <div className="col-4">[{pefData.examSession}]</div>

          <div className="col-5">
            <IconArrowRight24 className="rightIcon" />
          </div>
          <br />
        </a>
      </>
    );
  });
  return <>{examData}</>;
};

export default Exam;
