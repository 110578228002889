import React from "react";
import { ShowHidePanel } from "acca-design-system";
import parse from "html-react-parser";

function Accordion(props1) {
  const props = {
    id: "",
    headingLevel: "h3",
    headingContent: props1.accordionList,
    showLabel: "",
    hideLabel: "",
  };
  return (
    <div>
      <ShowHidePanel {...props}>
        <p>{parse(`${props1.accordionPara}`)}</p>
      </ShowHidePanel>
    </div>
  );
}

export default Accordion;
