import React from "react";
import { BodyCopy } from "acca-design-system";
import parse from "html-react-parser";
const Passrate = (props) => {
  const titlename = props.title.text;
  const subtitle = props.keyValue.heading;
  const para = props.keyValue.text;
  const img = props.keyValue.images[2].url;
  const link = props.keyValue.link;
  // console.log(titlename);
  return (
    <div className="passrate-Wrapper">
      <div className="container-pef ">
        <h3 className="h600  u-padding-bottom-2">{titlename}</h3>
        <div className="splitcolumn">
          <a href={link} target="_blank" rel="noreferrer">
            <div className="col-3">
              {/* <p className="h800 u-font-bold u-white u-padding-bottom-2">
              {titlename}
            </p> */}
              <img src={img} alt="" />
              {/* <div className="passRate u-padding-bottom-2">
              <p className="u-white u-overline-caption h800 u-padding-bottom-1">
                Self Study
              </p>
              <div className="progressbartab">
                <span className="u-white u-overline-title u-font-bold">
                  {"50% "}
                </span>
                <ProgressIndicator
                  currentStep={0.5}
                  totalSteps={1}
                  className=""
                />
              </div>
            </div> */}

              {/* <div className="passRate u-padding-bottom-2">
              <p className="u-white u-overline-caption h800 u-padding-bottom-1">
                Learning provider
              </p>
              <div className="progressbartab">
                <span className="u-white u-overline-title u-font-bold">
                  {"63% "}
                </span>
                <ProgressIndicator
                  currentStep={0.63}
                  totalSteps={1}
                  className=""
                />
              </div>
            </div> */}
            </div>
            <div className="col-9">
              <BodyCopy>
                <h5 className="h600 u-white u-font-heading-light ">
                  {subtitle}
                </h5>
                <p className="u-white u-padding-bottom-1">{parse(`${para}`)}</p>

                {/* <div className="u-white u-padding-bottom-1">
                <Anchor
                  className=" u-white u-font-bold"
                  onClick={function noRefCheck() { }}
                  to="/"
                >
                  Study support resources <IconOpenInANewWindow24 />
                </Anchor>
              </div>

              <div className="u-padding-bottom-1 u-white">
                <Anchor
                  className=" u-white u-font-bold"
                  onClick={function noRefCheck() { }}
                  to="/"
                >
                  Find a tuition provider <IconOpenInANewWindow24 />
                </Anchor>
              </div> */}
              </BodyCopy>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Passrate;
