import { ACTION_TYPES } from "../constants/Constants";

const pef = [];

export const fetchPefData = (state = pef, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PEF_DATA:
      return { ...state, pef: action.payload };
    default:
      return state;
  }
};
