import React, { useState } from "react";
import {
  Sidebar,
  SidebarContainer,
  NavBar,
  Hamburger,
  VerticalNav,
  Modal,
  BodyCopy,
  PageHeader,
} from "acca-design-system";
import {
  IconChevronLeft16,
  IconQuestionSolid16,
} from "@acca-digital/react-icons";

import { navigation, controls } from "./Sidebar.content";
import ExamModalContent from "../ExamModalContent";
import { useLocation } from "react-router-dom";

const PefSideBar = ({ children, ...args }) => {
  const location = useLocation();
  // console.log("Location", location.pathname);
  const [menuDrawOpen, setMenuDrawOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(location.pathname);
  const [showModal, setShowModal] = useState(null);

  const verticalNav = (
    <VerticalNav
      currentUrl={currentUrl}
      navigation={navigation}
      onItemClick={(url) => {
        setCurrentUrl(url);
      }}
      onBackBtnClick={() => {}}
      headerButton={{
        text: "Back to Exam Feedback",
        to: "/",
        icon: IconChevronLeft16,
      }}
    />
  );
  const testclick = (e) => {
    // console.log("clicked", e);
  };
  const openModal = () => {
    setShowModal(true);
  };

  const navBar = (
    <NavBar
      menuDrawOpen={menuDrawOpen}
      menu={{
        text: "",
        icon: Hamburger,
        onClick: testclick,
      }}
      links={[
        {
          text: "",
          icon: IconQuestionSolid16,
          onClick: openModal,
        },
      ]}
      onLinkClick={() => {
        // console.log("Sidebar link clicked");
      }}
      onMenuClick={() => {
        setMenuDrawOpen(!menuDrawOpen);
        // console.log(menuDrawOpen);
      }}
    />
  );

  return (
    <div>
      <SidebarContainer {...args}>
        <Sidebar
          navigation={verticalNav}
          navBar={navBar}
          isActive={menuDrawOpen}
          closeMenu={() => {
            setMenuDrawOpen(false);
          }}
          controls={controls}
          userProfile=""
        />
      </SidebarContainer>
      {showModal && (
        <Modal
          className="custom-modal"
          closeModal={() => setShowModal(false)}
          closeLinkText="Close"
          lotsOfContent={true}
        >
          <BodyCopy>
            <PageHeader quote="Exam marks explained" />
            <p>
              We use exam mark bands to show how you performed in each exam area
            </p>
            <ExamModalContent />
          </BodyCopy>
        </Modal>
      )}
    </div>
  );
};

export default PefSideBar;
