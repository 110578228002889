import React from "react";
import { Avatar } from "acca-design-system";
import Accordion from "../../Accordion/Accordion";
import Doughnutcircle from "../../Doughnut/Doughnut";

const SyllabusData = (props) => {
  const dataToDisplay = props.sData.map((sData) => {
    return (
      <div className="accordiantab-wrapper " key={sData.code}>
        <div className="accordian-tab ">
          <div className="container-pef ">
            <div className="row">
              <div className="col-1">
                <Avatar
                  style={{ background: "#64c8fa" }}
                  initials={sData.code}
                />
              </div>
              <div className="col-2">
                <h4>{sData.displayDesc}</h4>
              </div>
              <div className="column-reverseMobile">
                <div className="col-3">
                  {sData.feedbacks.map((feedbacks) => {
                    let feedbackType = "";
                    if (feedbacks.feedbackType === "PERF") {
                      feedbackType = "Performance feedback";
                    }
                    if (feedbacks.feedbackType === "NEXT_STEP") {
                      feedbackType = "Next steps";
                    }
                    return (
                      <Accordion
                        key={sData.feedbackType}
                        accordionList={feedbackType}
                        accordionPara={feedbacks.feedback}
                      />
                    );
                  })}
                </div>
                <div className="col-4">
                  <Doughnutcircle marks={sData.mark} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return <>{dataToDisplay}</>;
};

export default SyllabusData;
