/* eslint-disable no-undef */
import { useState, useEffect } from "react";
// import axios from "axios";
// import { useQuery } from "react-query";
// import AuthService from "../auth/authService";
import ApiService from "../apis/apiService";
// import apiConfigs from "../configs/apiConfigs";
// eslint-disable-next-line no-unused-vars
const useContent = (url) => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const response = await ApiService.CallApi({
        url: url,
        method: "GET",
        timeout: process.env.REACT_APP_API_TIMEOUT
          ? process.env.REACT_APP_API_TIMEOUT
          : 30000,
      })
        .then((resp) => {
          // console.log("AEM Content:", resp.data);
          setApiData(resp.data);
        })
        .catch(function (res) {
          if (res instanceof Error) {
          } else {
          }
        });
      setIsLoading(false);
    };

    fetchData();
  }, [url]);
  return { apiData, isLoading };
};

export default useContent;
