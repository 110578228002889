import React, { useState } from "react";

import Accordion from "../../Accordion/Accordion";
import Doughnutcircle from "../../Doughnut/Doughnut";
const ConstructedData = (props) => {
  const [show, setShow] = useState(false);

  const dataToDisplay = props.cData.map((cData) => {

    return (
      <div className="accordiantab-wrapper" key={cData.code}>
        <div className="accordian-tab ">
          <div className="container-pef ">
            <div className="row">
              <div
                className="col-1"
                style={{ display: show ? "display" : "none" }}
              ></div>
              <div className="col-2">
                <h4>{cData.displayName}</h4>
              </div>
              <div className="column-reverseMobile">
                <div
                  className="col-3"
                  style={{ padding: show ? "padding" : "0px" }}
                >
                  {cData.feedbacks.map((feedbacks) => {
                    return (
                      <Accordion
                        key={cData.feedbackType}
                        accordionList={"Performance feedback"}
                        accordionPara={feedbacks.feedback}
                      />
                    );
                  })}
                </div>
                <div className="col-4 no-marginTablet">
                  <Doughnutcircle marks={cData.mark} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <>{dataToDisplay}</>;
};

export default ConstructedData;
