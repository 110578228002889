import React from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../../constants/Routes";
import WithAccessControl from "../../containers/withAccessControl";

const Router = () => {
  const pages = routes.map(({ path, component, exact }) => {
    const Page = component;
    const Component = () => (
      <WithAccessControl>
        <Page />
      </WithAccessControl>
    );
    return <Route key={path} path={path} component={Component} exact={exact} />;
  });
  return <Switch>{pages}</Switch>;
};

export default Router;
