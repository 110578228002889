import React from "react";
import { Doughnut } from "acca-design-system";
// import { useLocation } from "react-router-dom";
function Doughnutcircle(props) {

  /* loacation path to check */
  // const location = useLocation();
  // console.log(location.pathname)

  // const resultData = (value) => {
  //   // console.log(value)
  //   const name = value;
  //   return name
  // };

  // const data = resultData('Result')
  // console.log(data)

  // const path = () => {
  //   if (location.pathname === "/time-management") {
  //     return data;
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <div>
      {/* <h3 className="u-text-center h600 u-padding-bottom-1">{location.pathname === '/time-management' ? 'Result' : ''}</h3> */}
      <Doughnut progress={props.marks} lineWidth={5}>

        <p className="u-black u-font-heading h400 marksNumber">
          {props.marks}
          <span
            style={{
              display: "inline-block",
              paddingLeft: "2px",
              fontSize: "16px",
            }}
          >
            %
          </span>
        </p>
        <span className="marks-data">MARK</span>
      </Doughnut>
    </div>
  );
}

export default Doughnutcircle;
