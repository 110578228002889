import React from "react";
import { connect } from "react-redux";
//import { PageHeader } from "acca-design-system";
function Dashboardpagetitle(props) {
  const pageDisplay = props.title;
  const pageDesc = props.desc;
  return (
    <div>
      <h1 className="subTitle h800 u-black u-font-bold u-padding-bottom-2 u-overline-caption">
        {pageDisplay}
      </h1>
      <h2 className="h400 u-black u-font-bold u-padding-bottom-2">
        {" "}
        {props.dashboardHeading}
      </h2>
      <p className="subTitle h700 u-black">{pageDesc}</p>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    dashboardHeading: state.singlePef.singlePef.examSubjectNameDisplay,
  };
};
export default connect(mapStateToProps)(Dashboardpagetitle);
