import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoadingDots } from "acca-design-system";
import AuthService from "../auth/authService";

const WithAccessControl = ({ children }) => {
  const location = useLocation();
  const [authLoaded, setAuthIsLoaded] = useState(false);
  useEffect(() => {
    if (!AuthService.isAuthenticated()) {
      AuthService.login();
    } else {
      setAuthIsLoaded(true);
    }
  }, [location]);

  if (!authLoaded) {
    return <LoadingDots />;
  }

  return <>{children}</>;
};
export default WithAccessControl;
