import React from "react";
import { connect } from "react-redux";
import { PageHeader, Doughnut } from "acca-design-system";
import parse from "html-react-parser";
const DashBoardHeader = (props) => {
  const bannerHeaderResult = props.singlePef.singlePef.resultFeedback;
  const bannerHeaderData = props.singlePef.singlePef;
  const header = props.dashboardData.resultCard.heading;

  return (
    <div className="container-pef ">
      <div className="pageTitle">
        <PageHeader
          pageTitle={header}
          quote={bannerHeaderData.examSubjectNameDisplay}
        />
        <p className="subTitle h800 yearTitle u-font-bold">
          For {bannerHeaderData.examSession}
        </p>
      </div>
      <div className="greeting-wrapper">
        <div className="doughnut-wrapper">
          <Doughnut progress={bannerHeaderResult.mark} lineWidth={6}>
            <p className="u-white u-font-heading h400 marksNumber">
              {bannerHeaderResult.mark}
              <span
                style={{
                  display: "inline-block",
                  paddingLeft: "2px",
                  fontSize: "16px",
                }}
              >
                %
              </span>
              <span className="marks-data">MARK</span>
            </p>
          </Doughnut>
        </div>
        <div className="greetings">
          {/* <h2 className="subTitle h-600">You Passed! Congratulations</h2> */}
          <p className="subTitle h-600">
            {parse(`${bannerHeaderResult.feedback}`)}
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    singlePef: state.singlePef,
    pefData: state.pefData,
    dashboardData: state.dashboardData.dashboard,
  };
};
export default connect(mapStateToProps)(DashBoardHeader);
