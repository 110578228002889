import React from "react";
import { BodyCopy } from "acca-design-system";
import { connect } from "react-redux";
import parse from "html-react-parser";
// import Doughnutcircle from "../Doughnut/Doughnut";

const DidyouKnow = (props) => {
  const data =
    props.dashboardContent.learningSupportCards[1].learningSupportCard2;
  const heading = data.heading;
  const para = data.text;

  const data1 =
    props.dashboardContent.learningSupportCards[2].learningSupportCard3;
  const heading1 = data1.heading;
  const para1 = data1.text;
  return (
    <div className="didyouknow-wrapper">
      <div className="container-pef">
        <div className="splitcolumn">
          <div className="col-6">
            <BodyCopy className="u-bg-red u-padding-top-2">
              <h6> </h6>

              <p className="h700">
                {parse(`${para}`)}
                {/* {" "}
                <a
                  className=" u-white u-font-bold"
                  onClick={function noRefCheck() {}}
                  to="/"
                >
                  Examiner's Reports
                </a>{" "}
                are the most used study support resource by students who passed
                on their first attempt. */}
              </p>
            </BodyCopy>
          </div>
          <div className="col-5 ">
            <BodyCopy className="u-padding-top-2">
              <h6>{parse(`${heading1}`)}</h6>

              <p>{parse(`${para1}`)}</p>
              {/* <div className="col-2">
              <h6>{heading1}</h6>
              <Doughnut progress={83} lineWidth={6}>
                <p className="u-black u-font-heading h400 marksNumber">
                  {"60%"}
                </p>
              </Doughnut>
            </div> */}
              {/* <div className="col-4 ">
              <h5 className="h600 u-black u-font-heading-light ">
                of students who used{" "}
                <Anchor
                  className="u-font-bold "
                  onClick={function noRefCheck() { }}
                  to="/objective-test-skills"
                >
                  ACCA's Practice Platform
                </Anchor>{" "}
                passed their exam on the first attempt
              </h5>
            </div> */}
            </BodyCopy>
          </div>
        </div>
      </div>
    </div>
  );
};

const maptoConnect = (state) => {
  return {
    dashboardContent: state.dashboardData.dashboard,
  };
};
export default connect(maptoConnect)(DidyouKnow);
