import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../../animationJson/ErrorAnimation.json";
import { BodyCopy } from "acca-design-system";

export const ErrorPage = (props) => {
  // console.log(props);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    class: "errorIocn",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="error-wrapper">
      <div className="error-container container-pef ">
        <Lottie
          options={defaultOptions}
          //   height={100%}
          //   width={100%}
        />
        <h2 className="u-text-center">503 - Service unavailable</h2>
        <h3 className="u-text-center">{props.message}</h3>
        <p className="paratext u-text-center">
          Please try again, or come back later.
        </p>
        <h4 className="u-text-center"> Need help?</h4>
        <div className="textWrap">
          <p className="paratext2 u-text-center">
            Get 24/7 support from our customer services team.
          </p>
          <BodyCopy>
            <p className="u-text-center">
              <a
                href="https://www.accaglobal.com/gb/en/footer-toolbar/contact-us.html"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Get help
              </a>
            </p>
          </BodyCopy>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
