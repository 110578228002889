import AuthService from "@acca-digital/auth-service";
import authConfig from "./authConfig";
const authService = new AuthService({
  authorities: authConfig.authorities,
  instanceConfig: authConfig.instanceConfig,
  scopes: authConfig.scopes,
});




export default authService;
