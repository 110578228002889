import React from "react";
import { connect } from "react-redux";
import Exam from "./Exam";
import { Card, PageHeader } from "acca-design-system";

const ExamPerformance = (props) => {
  // console.log("props", props.pefData);
  const title = props.headerTitle;
  return (
    <div className="Exam-wrapper container-pef ">
      <PageHeader className="ExamTitle" pageTitle={title} />
      <Card className="Card Card_result" center>
        <Exam pefData={props.pefData} />
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    singlePef: state.singlePef,
  };
};
export default connect(mapStateToProps)(ExamPerformance);
