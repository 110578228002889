import { ACTION_TYPES } from "../constants/Constants";

const content = {};

export const dashboardAemData = (state = content, action) => {
  // console.log("Action:", action);
  switch (action.type) {
    case ACTION_TYPES.DASHBOARD_AEM_CONTENT:
      return { ...state, dashboard: action.payload };
    default:
      return state;
  }
};
