import React from "react";
import { BodyCopy } from "acca-design-system";
import { connect } from "react-redux";
import parse from "html-react-parser";

function NextSteps(props) {
  const content = props.nextStep.singlePef.nextStepsFeedback;
  const cardImg = props.content.images[2].url;
  return (
    <div className="nextsteps-Wrapper">
      <div className="splitcolumn">
        <div className="col-3">
          <div className="desktop-image" style={{ backgroundImage: `url(${cardImg})` }} />
          <img className="mobile-image" src={cardImg} alt="" />

          <div className="img-content">
            <h2 className="u-white h400">{content.displayName}</h2>
          </div>
        </div>
        <div className="col-9 u-white">
          <BodyCopy>
            <div className="paraContainer">{parse(`${content.feedback}`)}</div>
          </BodyCopy>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    nextStep: state.singlePef,
  };
};
export default connect(mapStateToProps)(NextSteps);
