import { ACTION_TYPES } from "../constants/Constants";

export const pefAvailable = (payload) => {
  return {
    type: ACTION_TYPES.PEF_AVAILAIBLE,
    payload,
  };
};

export const fetchPefData = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_PEF_DATA,
    payload,
  };
};

export const singlePef = (payload) => {
  return {
    type: ACTION_TYPES.FETCH_SINGLE_PEF_DATA,
    payload,
  };
};

export const dashboardAemData = (payload) => ({
  type: ACTION_TYPES.DASHBOARD_AEM_CONTENT,
  payload,
});

export const currentPefId = (payload) => ({
  type: ACTION_TYPES.PEFID,
  payload,
});
