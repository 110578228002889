import React, { useState, useEffect } from "react";
import { LoadingDots, Footer } from "acca-design-system";
import { useDispatch } from "react-redux";
import LandingPageHeader from "./LandingPageHeader";
import ExamPerformance from "./ExamPerformance";
import Helpsupport from "../Helpsupport/Helpsupport";
import ApiService from "../../apis/apiService";
import AuthService from "../../auth/authService";
import apiConfigs from "../../configs/apiConfigs";
import ErrorPage from "../Errorpage/Errorpage";
import { fetchPefData, dashboardAemData, pefAvailable } from "../../actions";
import useContent from "../../hooks/useContent";

const LandingPage = React.memo((props) => {
  const [loading, setLoading] = useState();
  const [pefData, setPefData] = useState([]);

  const [pefStatusData, setPefStatus] = useState([]);

  const dispatch = useDispatch();
  const { apiData, isLoading } = useContent(
    apiConfigs.endpoints.templates.landingPage
  );
  const dashboard = useContent(apiConfigs.endpoints.templates.dashboard);
  dispatch(dashboardAemData(dashboard.apiData));

  useEffect(() => {
    setLoading(true);
    const fetchPef = async () => {
      const auth = await AuthService.loadAuthService();
      const accaId = auth.idTokenClaims.userid;
      const response = await ApiService.CallApi({
        url: apiConfigs.endpoints.user.pefStatus(accaId),
        method: "GET",
        timeout: process.env.REACT_APP_API_TIMEOUT
          ? process.env.REACT_APP_API_TIMEOUT
          : 30000,
      })
        .then((resp) => {
          setPefStatus(resp.data);
          dispatch(pefAvailable(resp.data));
          setLoading(false);
        })
        .catch(function (res) {
          if (res instanceof Error) {
            console.log(res);
          } else {
            console.log(res.data);
          }
        });
    };

    fetchPef();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchPef = async (dispatch) => {
      const auth = await AuthService.loadAuthService();
      const accaId = auth.idTokenClaims.userid;
      const response = await ApiService.CallApi({
        url: apiConfigs.endpoints.user.pef(accaId),
        method: "GET",
        timeout: process.env.REACT_APP_API_TIMEOUT
          ? process.env.REACT_APP_API_TIMEOUT
          : 30000,
      })
        .then((resp) => {
          // console.log("PEFDATA:", resp.data);
          setPefData(resp.data.pef);
          setLoading(false);
          dispatch(fetchPefData(resp.data));
        })
        .catch(function (res) {
          if (res instanceof Error) {
            return <ErrorPage />;
          } else {
          }
        });
    };

    fetchPef();
  }, []);
  if (loading || isLoading) {
    return <LoadingDots />;
  }

  const headerContent = apiData.pageTitle.text;
  const ExamHeaderTitle = apiData.examPerformanceSectionHeading.text;
  const headerSubtitle = apiData.pageDescription.value;
  const helpcardContent = apiData;
  if (pefStatusData.isAvailable === false) {
    return (
      <ErrorPage message="Sorry, you do not appear to have Exam Feedback" />
    );
  }
  return (
    <React.Fragment>
      <LandingPageHeader titleHd={headerContent} subtitle={headerSubtitle} />
      <ExamPerformance headerTitle={ExamHeaderTitle} pefData={pefData} />
      <Helpsupport helpCardData={helpcardContent} />
      <Footer className="container-pef " children={""}></Footer>
    </React.Fragment>
  );
});

export default LandingPage;
