
    export const textColorChange = () => {
    const isBackground = true;
    if (isBackground === true ){
        return isBackground ? 'text-black' : 'text-normal'   
    }
    else{
        return null;
        }
    }

  