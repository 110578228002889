import React from "react";
import { connect } from "react-redux";
import { Doughnut } from "acca-design-system";

const examModalContent = (props) => {
  const content = props.modalData.markBands;
  const modalContent = content.map((content) => {
    return (
      <div className="custam-modal-body">
        <div className="modal-doughnut">
          <Doughnut progress={content.max} lineWidth="6" shouldAnimate={false}>
            <p className="u-font-heading h800  marksNumber">
              {content.displayName} <span className="marks-data">MARK</span>
            </p>
          </Doughnut>
        </div>
        <div className="doughnut-details">{content.feedback} </div>
      </div>
    );
  });

  return <>{modalContent}</>;
};

const mapStateToProps = (state) => {
  return {
    modalData: state.singlePef.singlePef,
  };
};

export default connect(mapStateToProps)(examModalContent);
