/* eslint-disable camelcase */
import React, { useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { connect } from "react-redux";
import Router from "../Router/Router";
import ErrorPage from "../Errorpage/Errorpage";

const AppInitialiser = () => {
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  return <Router />;
};

const WithErrorHandling = () => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        // <ErrorPage
        //   isTakeOver
        //   error={error}
        //   resetErrorBoundary={resetErrorBoundary}
        // />
        <ErrorPage message="Sorry, something went wrong." />
      )}
    >
      <AppInitialiser />
    </ErrorBoundary>
  );
};
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    pefAvalable: state.pefAvalable,
  };
};
export default connect(mapStateToProps)(WithErrorHandling);
